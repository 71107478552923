*,
::after,
::before {
  box-sizing: border-box;
}

// REPLACE BOOTSTRAP WITH THESE RULES FOR NAVBAR

.nav {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column !important;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
}


hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
  display: block;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

ul {
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  list-style-type: disc;
}

img {
  vertical-align: middle;
  border-style: none;
}

blockquote {
  margin: 0 0 1rem;
}

// Add Bootstrap table styling

table {
  border-collapse: collapse;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
}

.table td,
.table th {
  padding: .75rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, .075);
}

.table th {
  text-align: left;
  vertical-align: middle;
}

/* Overwrite a few bootstrap table definitions */

.table thead th[scope=col] {
  border-top: 0px;
  vertical-align: top;
}

.table th[scope=rowgroup] {
  font-weight: initial;
}

.table th[scope=row] {
  font-weight: initial;
}

.table tbody+tbody {
  border-top: 1px solid #dee2e6;
}

// Checkbox spacing on super-admin page
// label {
//   display: inline-block;
//   margin-bottom: .5rem;
// }

.table-sm th,
.table-sm td {
  padding: 0.3rem !important;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

// alert
//TODO: search for class="alert" and modify error messages
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

// misc
.text-center {
  text-align: center !important;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

.text-muted {
  color: #6c757d !important;
}