@import './scss/bootstrap-overrides.scss';
@import './scss/material-overrides.scss';
@import "../node_modules/quill/dist/quill.snow.css";
@import "../node_modules/quill/dist/quill.bubble.css";


body {
  margin: 0;
  font-weight: 400;
  background-color: #fafafa;
  // line-height: 1.5; // from bootstrap
}

h1 {
  font-weight: 500;
  margin-top: 0px; // Vertically align title bar
}

h2 {
  font-size: 1em;
  font-weight: inherit;
}

strong {
  font-weight: 700;
}

// Format Quill (rich text editor) defaults
.ql-container {
  font-size: 16px;
}

@import './scss/legend.scss';


.title-bar {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  margin-bottom: 10px;
  padding-bottom: 1rem;
  align-items: center;
  width: 100%
}

.title-bar h1 {
  color: rgb(0, 48, 92);
  font-size: 24px;
  padding-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  width: 12rem;
}

.title-bar button {
  margin-right: 1rem;
}

a {
  color: #0d47a1;
  text-decoration: none; // from bootstrap
  background-color: transparent; // from bootstrap
}

.link {
  color: #0d47a1;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.description {
  color: darkgray;
  font-size: 90%;
  // font-weight: 300;
}

.ql-bubble .ql-editor {
  padding: 0px 0px 6px 0px;
}

.ql-editor {
  font-weight: 300;
}

.fake-mat-quill {
  position: relative;
  width: 70%;
  margin-left: 1rem;
}

.fake-mat-quill quill-editor {

  display: block;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #757575;
  background-color: rgba(0, 0, 0, 0);
}

.fake-mat-quill quill-editor:focus-within {
  outline: 0;
  border-bottom: 2px solid #4A5DB6
}


/* .fake-mat-quill .fake-label {
    position: absolute;
    top: 13px;
    pointer-events: none;
    transition: top 0.3s ease, font-size 0.3s ease;
}

.fake-mat-quill select:focus+.fake-label,
.fake-mat-quill select:valid+.fake-label {
    top: 0px;
    font-size: 75%;
} */

// button:focus {
//   box-shadow: 
//   0 0 0 2px white,
//   0 0 0 5px blue !important;
// }

.mat-expansion-panel:focus-within {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}

button.cdk-keyboard-focused {
  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}

/* Remove spinners for accessibility */
/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}